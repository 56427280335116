import { createSlice } from "@reduxjs/toolkit";

export interface InitialMapState {
    lng: number;
    lat: number;
    featureProps: any;
}

export const initialState: InitialMapState = {
    lng: 37.619590759277344,
    lat: 55.75126974369615,
    featureProps: null,
};

const map = createSlice({
    initialState,
    name: "map",
    reducers: {
        updateCurrentCoordinates: (state, { payload }) => {
            state.lng = payload[0];
            state.lat = payload[1];
        },
    },
});

export default {
    state: initialState,
    reducer: map.reducer,
    actions: map.actions,
};
