import { createSlice } from "@reduxjs/toolkit";

export interface InitialPopupState {
    show: boolean;
    camIndex: number | null;
}

export const initialState: InitialPopupState = {
    show: false,
    camIndex: null,
};

const popup = createSlice({
    initialState,
    name: "popup",
    reducers: {
        showPopup: (state) => {
            state.show = !state.show;
        },
        setCamIndex: (state, { payload }) => {
            state.camIndex = payload;
        },
    },
});
export const { showPopup, setCamIndex } = popup.actions;

export default {
    state: initialState,
    reducer: popup.reducer,
};
