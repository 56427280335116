/* tslint:disable */
/* eslint-disable */
/**
 * Videowall Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise } from "axios";
import * as globalImportUrl from "url";
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs } from "../base";
import { Configuration } from "../configuration";
/**
 * DictionariesApi - axios parameter creator
 * @export
 */
export const DictionariesApiAxiosParamCreator = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает все layouts (варианты размещения элементов на видеостене)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowall/layouts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * DictionariesApi - functional programming interface
 * @export
 */
export const DictionariesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает все layouts (варианты размещения элементов на видеостене)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutsGet(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DictionariesApiAxiosParamCreator(configuration).layoutsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    };
};

/**
 * DictionariesApi - factory interface
 * @export
 */
export const DictionariesApiFactory = function(
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    return {
        /**
         *
         * @summary Возвращает все layouts (варианты размещения элементов на видеостене)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutsGet(options?: any): AxiosPromise<Array<string>> {
            return DictionariesApiFp(configuration)
                .layoutsGet(options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionariesApi - object-oriented interface
 * @export
 * @class DictionariesApi
 * @extends {BaseAPI}
 */
export class DictionariesApi extends BaseAPI {
    /**
     *
     * @summary Возвращает все layouts (варианты размещения элементов на видеостене)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public layoutsGet(options?: any) {
        return DictionariesApiFp(this.configuration)
            .layoutsGet(options)
            .then((request) => request(this.axios, this.basePath));
    }
}
