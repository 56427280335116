import { headerReducer, headerState } from "features/main-page/header/store";
import cameraStore, { initialState as cameraState } from "shared/store/cameras/camSlice";
import mapStore, { initialState as mapState } from "shared/store/map/mapSlice";
import modalStore, { initialState as modalState } from "shared/store/modals/modalSlice";
import popupStore, { initialState as popupState } from "shared/store/popup/popupSlice";
import videowallsStore, { initialState as videowallState } from "shared/store/videowall/videowallsSlice";

export const states = {
    cam: cameraState,
    popup: popupState,
    presets: videowallState,
    modal: modalState,
    header: headerState,
    map: mapState,
};

export const reducers = {
    cam: cameraStore.reducer,
    popup: popupStore.reducer,
    presets: videowallsStore.reducer,
    modal: modalStore.reducer,
    header: headerReducer,
    map: mapStore.reducer,
};

export type States = typeof states;
