import { createSlice } from "@reduxjs/toolkit";
import { InitialHeaderState } from "./types";

export const initialState: InitialHeaderState = {
    showHeader: true,
    fullscreenMode: false,
};
export const { actions, reducer } = createSlice({
    initialState,
    name: "header",
    reducers: {
        setShowHeader: (state) => {
            return {
                ...state,
                showHeader: true,
            };
        },
        setHideHeader: (state) => {
            return {
                ...state,
                showHeader: false,
            };
        },
    },
});
