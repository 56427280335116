import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { batch } from "react-redux";
import {
    Camera,
    DictionariesApiFactory,
    GroupsApiFactory,
    Videowall,
    VideowallLayout,
    VideowallsApiFactory
} from "shared/api";
import modalSlice from "../modals/modalSlice";
import videowallsSlice from "./videowallsSlice";

type CancelObject = { [key: string]: Function };
const cancelTokens: CancelObject = {};
const { CancelToken } = axios;

export const copyVideowall = createAsyncThunk<
    Videowall,
    { videowall: Videowall; videowallId: number; groupName: string; presetIndex: number },
    ThunkApi
>(
    "presets/copyVideowall",
    //@ts-ignore
    async ({ videowall, videowallId }) => {
        try {
            const cameras = await VideowallsApiFactory()
                .videowallsIdGet(videowallId!, undefined, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelTokens["fetchVideowallById"] = c;
                    }),
                })
                .then((res) => res.data.cameras);

            const { data } = await VideowallsApiFactory().videowallsPost(
                {
                    id: videowall.id,
                    name: `${videowall.name}-копия`,
                    group: videowall.group,
                    isPublic: false,
                    layout: videowall.layout,
                    cameras: cameras,
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelTokens["copyVideowall"] = c;
                    }),
                }
            );
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);

/**
 * @thunk - удаление видеостены из группы
 */
export const deleteVideowallById = createAsyncThunk<
    void,
    { deletePresetId: number | null; groupName: string | null },
    ThunkApi
>("presets/deleteVideowallById", async ({ deletePresetId, groupName }, { getState, dispatch }) => {
    try {
        await VideowallsApiFactory()
            .videowallsIdDelete(deletePresetId!)
            
    } catch (error) {
        console.error(error, "Ошибка в deleteVideowallById");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
});
/**
 * @thunk - Смена названия видеостены
 */
export const renameVideowall = createAsyncThunk<
    void,
    { newName: string; groupName: string; id: number | null; currentPresetId?: number },
    ThunkApi
>("presets/renameVideowall", async ({ id, newName }, { dispatch }) => {
    try {
        await VideowallsApiFactory().videowallsIdRenamePost(id!, { name: newName });
    } catch (error) {
        console.error(error, "Ошибка в renameVideowall");
        batch(() => {
            if (error.response) {
                dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
            } else if (error.request) {
                dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
            }
        });
    }
});

/**
 * @thunk Сделать видеостену публичной
 * @remark только админ, может указать список групп доступа
 */

export const publicVideowall = createAsyncThunk<void, { groupName: string; videowallId: number }, ThunkApi>(
    "presets/publicVideowall",
    async ({ groupName, videowallId }, { dispatch }) => {
        try {
            await VideowallsApiFactory().videowallsIdMakePublicPost(videowallId);
        } catch (error) {
            console.error(error, "Ошибка в publicVideowall");
            batch(() => {
                if (error.response) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
                } else if (error.request) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
                }
            });
        }
    }
);

/**
 * @thunk Сделать видеостену приватной
 */
export const privateVidowall = createAsyncThunk<void, { videowallId: number; groupName: string }>(
    "preset/privateVideowall",
    async ({ videowallId }, { dispatch }) => {
        try {
            await VideowallsApiFactory().videowallsIdMakePrivatePost(videowallId);
        } catch (error) {
            console.error(error, "Ошибка в privateVidowall");
            batch(() => {
                if (error.response) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
                } else if (error.request) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
                }
            });
        }
    }
);

// POST /groups/rename - перименование видеостены
/**
 * @thunk - смена названия группы
 */
export const renameGroup = createAsyncThunk<void, { oldName: string; newName: string; groupName: string }, ThunkApi>(
    "presets/renameGroup",
    async ({ newName, oldName }, { dispatch }) => {
        try {
            await GroupsApiFactory()
                .groupsRenamePost({
                    oldName,
                    newName,
                })
                .then(() => dispatch(videowallsSlice.actions.updateLocalGroupName({ newName, groupName: oldName })));
        } catch (error) {
            console.error(error, "Ошибка в renameVideowall");
            batch(() => {
                if (error.response) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
                } else if (error.request) {
                    dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
                }
            });
        }
    }
);

/**
 * @thunk - загрузка списка сгруппированных видеостен без камер
 */
export const fetchAllVideowalls = (): AppThunk => async (dispatch: Dispatch, getState) => {
    try {
        if (cancelTokens["fetchAllVideowalls"]) cancelTokens["fetchAllVideowalls"]("canceled");
        await VideowallsApiFactory()
            .videowallsGroupedGet(undefined, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelTokens["fetchAllVideowalls"] = c;
                }),
            })
            .then((res) => {
                const preparedData = res.data.map((group) => {
                    return {
                        group: group.group,
                        videowalls: group.videowalls?.map((videowall) => {
                            return {
                                creatorId: videowall.creatorId,
                                id: videowall.id,
                                name: videowall.name,
                                group: videowall.group,
                                isPublic: videowall.isPublic,
                                layout: videowall.layout,
                            };
                        }),
                    };
                });
                dispatch(videowallsSlice.actions.updateAllGroups(preparedData));
            });
    } catch (error) {
        console.error(error, "Ошибка в fetchAllVideowalls");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};

// GET /videowalls/{id} - возвращает параметры видеостены (раскладки) с камерами
export const fetchVideowallById = (id: number): AppThunk => async (dispatch: Dispatch, getState) => {
    if (typeof id !== "number") return;
    try {
        if (cancelTokens["fetchVideowallById"]) cancelTokens["fetchVideowallById"]("canceled");
        await VideowallsApiFactory()
            .videowallsIdGet(id, undefined, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelTokens["fetchVideowallById"] = c;
                }),
            })
            .then((res) => {
                batch(() => {
                    dispatch(videowallsSlice.actions.setCurrentPreset(res.data));
                    dispatch(modalSlice.actions.setCurrentLayout(res.data.layout));
                });
            });
    } catch (error) {
        console.error(error, "ошибка в fetchVideowallById");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};
// POST /videowalls - сохраняет видеостену с камерами
export const saveNewVideowall = (
    videowallName: string,
    groupName: string,
    layout: VideowallLayout,
    cameras: Camera[],
    afterSave?: Function
): AppThunk => async (dispatch: Dispatch) => {
    try {
        if (cancelTokens["saveNewVideowall"]) cancelTokens["saveNewVideowall"]("canceled");
        await VideowallsApiFactory()
            .videowallsPost(
                {
                    id: 0,
                    name: videowallName,
                    group: groupName,
                    isPublic: false,
                    layout: layout ? layout : VideowallLayout.Layout2X2,
                    cameras: cameras ? cameras.filter((el) => el !== null) : [],
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelTokens["saveNewVideowall"] = c;
                    }),
                }
            )
            .then((res: { data: any }) =>
                !afterSave ? dispatch(videowallsSlice.actions.setCurrentPreset(res.data)) : afterSave()
            )
            // @ts-ignore
            .then(() => dispatch(fetchAllVideowalls()));
    } catch (error) {
        console.error(error, "Ошибка в saveCurrentVideowall");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};

// PUT /videowalls/{id} - изменение видеостены
export const updateVideowallById = (videowall: Videowall): AppThunk => async (dispatch: Dispatch, getState) => {
    if (cancelTokens["updateVideowallById"]) cancelTokens["updateVideowallById"]("canceled");
    // const store = getState();
    // const currentPreset = store.presets.currentPreset;
    try {
        await VideowallsApiFactory()
            .videowallsIdPut(
                videowall.id!,
                {
                    id: videowall.id,
                    name: videowall.name,
                    group: videowall.group,
                    isPublic: videowall.isPublic,
                    layout: videowall.layout,
                    cameras: videowall.cameras
                        ? videowall.cameras.filter((el) => el !== null).map((el) => ({ ...el, source: "codd" }))
                        : [],
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelTokens["updateVideowallById"] = c;
                    }),
                }
            )
            .then(() => dispatch(videowallsSlice.actions.setLayoutAfterFetch(videowall)));
    } catch (error) {
        console.error(error, "Ошибка в updateVideowallById");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};

// POST /videowalls/{id}/move - перемещение видеостены (в другую группу)
export const moveVideowallInSomeGroup = (
    draggableIndex: number,
    droppableIndex: number,
    draggableGroup: string,
    droppableGroup: string
): AppThunk => async (dispatch: Dispatch, getState) => {
    try {
        const state = getState();
        const videowallId = state.presets.allGroups.find((group) => group.group === draggableGroup)?.videowalls![
            draggableIndex
        ].id;

        await VideowallsApiFactory()
            .videowallsIdMovePost(videowallId!, { group: droppableGroup })
            .then(() => {
                return batch(() => {
                    dispatch(
                        videowallsSlice.actions.moveVideowall({
                            draggableGroup,
                            droppableGroup,
                            videowallId,
                            droppableIndex,
                        })
                    );
                    dispatch(videowallsSlice.actions.deleteAfterMove({ draggableGroup, droppableGroup, videowallId }));
                    dispatch(videowallsSlice.actions.deleteEmpyGroups());
                });
            });
    } catch (error) {
        console.error(error, "Ошибка в dropVideowallInSomeGroup");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};

// GET /groups - возвращает все группы
export const getVideowallGroups = (): AppThunk => async (dispatch: Dispatch, getState) => {
    try {
        await GroupsApiFactory()
            .groupsGet()
            .then(({ data }) => dispatch(videowallsSlice.actions.updateGropNames(data)));
    } catch (error) {
        console.error(error, "Ошибка в getVideowallGroups");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};

// GET /layouts - возвращает все laoyuts
export const getLayouts = (): AppThunk => async (dispatch: Dispatch, getState) => {
    try {
        await DictionariesApiFactory()
            .layoutsGet()
            .then(({ data }) => dispatch(videowallsSlice.actions.setAllLayouts(data)));
    } catch (error) {
        console.error(error, "Ошибка в getLayouts");
        if (error.response) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: error.response.status }));
        } else if (error.request) {
            dispatch(modalSlice.actions.setShowNotificationModal({ status: 500 }));
        }
    }
};
