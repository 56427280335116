import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Camera } from "../../api/gen/models/camera";

export interface InitialState {
    cams: Camera[];
    currentCam?: Camera;
    draggableIndex?: number;
    droppableIndex?: number;
}

export const initialState: InitialState = {
    cams: [],
};

const cams = createSlice({
    initialState,
    name: "cams",
    reducers: {
        setCurrentCam: (state, { payload: { source, code, description, position } }: PayloadAction<Camera>) => {
            state.currentCam = { source, code, description, position };
        },
        clearCurrentCam: (state) => {
            state.cams = [];
            state.currentCam = undefined;
        },
    },
});

export default {
    state: initialState,
    reducer: cams.reducer,
    actions: cams.actions,
};
