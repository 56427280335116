/* tslint:disable */
/* eslint-disable */
/**
 * Videowall Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise } from "axios";
import * as globalImportUrl from "url";
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import { Configuration } from "../configuration";
// @ts-ignore
import { Videowall, VideowallGroup, VideowallMoveRequest, VideowallRenameRequest } from "../models";
/**
 * VideowallsApi - axios parameter creator
 * @export
 */
export const VideowallsApiAxiosParamCreator = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsGet: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowalls`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает сгруппированный список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsGroupedGet: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowalls/grouped`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Удаляет видеостену
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdDelete."
                );
            }
            const localVarPath = `/videowalls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает видеостену с камерами.
         * @param {number} id Идентификатор видеостены
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdGet: async (id: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdGet."
                );
            }
            const localVarPath = `/videowalls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Делает стену приватной
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMakePrivatePost: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdMakePrivatePost."
                );
            }
            const localVarPath = `/videowalls/{id}/make-private`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Опубликовывает видеостену (перестает быть приватной)
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMakePublicPost: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdMakePublicPost."
                );
            }
            const localVarPath = `/videowalls/{id}/make-public`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Переносит видеостену в другую группу
         * @param {number} id Идентификатор видеостены
         * @param {VideowallMoveRequest} [videowallMoveRequest] Объект с названием группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMovePost: async (
            id: number,
            videowallMoveRequest?: VideowallMoveRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdMovePost."
                );
            }
            const localVarPath = `/videowalls/{id}/move`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            const needsSerialization =
                typeof videowallMoveRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(videowallMoveRequest !== undefined ? videowallMoveRequest : {})
                : videowallMoveRequest || "";

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Изменяет видеостену и привязанные камеры.  Идентификатор видеостены должен быть указан в uri и теле запроса.  Все неуказанные камеры удалятся с видеостены.  Не изменяет настройки доступа.
         * @param {number} id
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdPut: async (id: number, videowall?: Videowall, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdPut."
                );
            }
            const localVarPath = `/videowalls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            const needsSerialization =
                typeof videowall !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(videowall !== undefined ? videowall : {})
                : videowall || "";

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Переименовывает видеостену
         * @param {number} id Идентификатор видеостены
         * @param {VideowallRenameRequest} [videowallRenameRequest] Объект с новым именем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdRenamePost: async (
            id: number,
            videowallRenameRequest?: VideowallRenameRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError(
                    "id",
                    "Required parameter id was null or undefined when calling videowallsIdRenamePost."
                );
            }
            const localVarPath = `/videowalls/{id}/rename`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            const needsSerialization =
                typeof videowallRenameRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(videowallRenameRequest !== undefined ? videowallRenameRequest : {})
                : videowallRenameRequest || "";

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Создает видеостену и привязывает камеры.  Видеостена создается как приватная.  В запросе камеры могут не указываться (пустая видеостена).
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsPost: async (videowall?: Videowall, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowalls`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            const needsSerialization =
                typeof videowall !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(videowall !== undefined ? videowall : {})
                : videowall || "";

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VideowallsApi - functional programming interface
 * @export
 */
export const VideowallsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsGet(
            userId?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Videowall>>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsGet(
                userId,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Возвращает сгруппированный список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsGroupedGet(
            userId?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VideowallGroup>>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsGroupedGet(
                userId,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Удаляет видеостену
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdDelete(
            id: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdDelete(
                id,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Возвращает видеостену с камерами.
         * @param {number} id Идентификатор видеостены
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdGet(
            id: number,
            userId?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Videowall>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdGet(
                id,
                userId,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Делает стену приватной
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdMakePrivatePost(
            id: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdMakePrivatePost(
                id,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Опубликовывает видеостену (перестает быть приватной)
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdMakePublicPost(
            id: number,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdMakePublicPost(
                id,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Переносит видеостену в другую группу
         * @param {number} id Идентификатор видеостены
         * @param {VideowallMoveRequest} [videowallMoveRequest] Объект с названием группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdMovePost(
            id: number,
            videowallMoveRequest?: VideowallMoveRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdMovePost(
                id,
                videowallMoveRequest,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Изменяет видеостену и привязанные камеры.  Идентификатор видеостены должен быть указан в uri и теле запроса.  Все неуказанные камеры удалятся с видеостены.  Не изменяет настройки доступа.
         * @param {number} id
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdPut(
            id: number,
            videowall?: Videowall,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdPut(
                id,
                videowall,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Переименовывает видеостену
         * @param {number} id Идентификатор видеостены
         * @param {VideowallRenameRequest} [videowallRenameRequest] Объект с новым именем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsIdRenamePost(
            id: number,
            videowallRenameRequest?: VideowallRenameRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsIdRenamePost(
                id,
                videowallRenameRequest,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Создает видеостену и привязывает камеры.  Видеостена создается как приватная.  В запросе камеры могут не указываться (пустая видеостена).
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videowallsPost(
            videowall?: Videowall,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Videowall>> {
            const localVarAxiosArgs = await VideowallsApiAxiosParamCreator(configuration).videowallsPost(
                videowall,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    };
};

/**
 * VideowallsApi - factory interface
 * @export
 */
export const VideowallsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Возвращает список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsGet(userId?: string, options?: any): AxiosPromise<Array<Videowall>> {
            return VideowallsApiFp(configuration)
                .videowallsGet(userId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает сгруппированный список видеостен, доступных пользователю. Без камер.
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsGroupedGet(userId?: string, options?: any): AxiosPromise<Array<VideowallGroup>> {
            return VideowallsApiFp(configuration)
                .videowallsGroupedGet(userId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Удаляет видеостену
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdDelete(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает видеостену с камерами.
         * @param {number} id Идентификатор видеостены
         * @param {string} [userId] ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdGet(id: number, userId?: string, options?: any): AxiosPromise<Videowall> {
            return VideowallsApiFp(configuration)
                .videowallsIdGet(id, userId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Делает стену приватной
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMakePrivatePost(id: number, options?: any): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdMakePrivatePost(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Опубликовывает видеостену (перестает быть приватной)
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMakePublicPost(id: number, options?: any): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdMakePublicPost(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Переносит видеостену в другую группу
         * @param {number} id Идентификатор видеостены
         * @param {VideowallMoveRequest} [videowallMoveRequest] Объект с названием группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdMovePost(
            id: number,
            videowallMoveRequest?: VideowallMoveRequest,
            options?: any
        ): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdMovePost(id, videowallMoveRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Изменяет видеостену и привязанные камеры.  Идентификатор видеостены должен быть указан в uri и теле запроса.  Все неуказанные камеры удалятся с видеостены.  Не изменяет настройки доступа.
         * @param {number} id
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdPut(id: number, videowall?: Videowall, options?: any): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdPut(id, videowall, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Переименовывает видеостену
         * @param {number} id Идентификатор видеостены
         * @param {VideowallRenameRequest} [videowallRenameRequest] Объект с новым именем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsIdRenamePost(
            id: number,
            videowallRenameRequest?: VideowallRenameRequest,
            options?: any
        ): AxiosPromise<void> {
            return VideowallsApiFp(configuration)
                .videowallsIdRenamePost(id, videowallRenameRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Создает видеостену и привязывает камеры.  Видеостена создается как приватная.  В запросе камеры могут не указываться (пустая видеостена).
         * @param {Videowall} [videowall]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videowallsPost(videowall?: Videowall, options?: any): AxiosPromise<Videowall> {
            return VideowallsApiFp(configuration)
                .videowallsPost(videowall, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for videowallsGet operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsGetRequest
 */
export interface VideowallsApiVideowallsGetRequest {
    /**
     * ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
     * @type {string}
     * @memberof VideowallsApiVideowallsGet
     */
    readonly userId?: string;
}

/**
 * Request parameters for videowallsGroupedGet operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsGroupedGetRequest
 */
export interface VideowallsApiVideowallsGroupedGetRequest {
    /**
     * ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
     * @type {string}
     * @memberof VideowallsApiVideowallsGroupedGet
     */
    readonly userId?: string;
}

/**
 * Request parameters for videowallsIdDelete operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdDeleteRequest
 */
export interface VideowallsApiVideowallsIdDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof VideowallsApiVideowallsIdDelete
     */
    readonly id: number;
}

/**
 * Request parameters for videowallsIdGet operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdGetRequest
 */
export interface VideowallsApiVideowallsIdGetRequest {
    /**
     * Идентификатор видеостены
     * @type {number}
     * @memberof VideowallsApiVideowallsIdGet
     */
    readonly id: number;

    /**
     * ID пользователя. Временно используется мобильным приложением для авторизации.              Остальные клиенты должны использовать claims.
     * @type {string}
     * @memberof VideowallsApiVideowallsIdGet
     */
    readonly userId?: string;
}

/**
 * Request parameters for videowallsIdMakePrivatePost operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdMakePrivatePostRequest
 */
export interface VideowallsApiVideowallsIdMakePrivatePostRequest {
    /**
     *
     * @type {number}
     * @memberof VideowallsApiVideowallsIdMakePrivatePost
     */
    readonly id: number;
}

/**
 * Request parameters for videowallsIdMakePublicPost operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdMakePublicPostRequest
 */
export interface VideowallsApiVideowallsIdMakePublicPostRequest {
    /**
     *
     * @type {number}
     * @memberof VideowallsApiVideowallsIdMakePublicPost
     */
    readonly id: number;
}

/**
 * Request parameters for videowallsIdMovePost operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdMovePostRequest
 */
export interface VideowallsApiVideowallsIdMovePostRequest {
    /**
     * Идентификатор видеостены
     * @type {number}
     * @memberof VideowallsApiVideowallsIdMovePost
     */
    readonly id: number;

    /**
     * Объект с названием группы
     * @type {VideowallMoveRequest}
     * @memberof VideowallsApiVideowallsIdMovePost
     */
    readonly videowallMoveRequest?: VideowallMoveRequest;
}

/**
 * Request parameters for videowallsIdPut operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdPutRequest
 */
export interface VideowallsApiVideowallsIdPutRequest {
    /**
     *
     * @type {number}
     * @memberof VideowallsApiVideowallsIdPut
     */
    readonly id: number;

    /**
     *
     * @type {Videowall}
     * @memberof VideowallsApiVideowallsIdPut
     */
    readonly videowall?: Videowall;
}

/**
 * Request parameters for videowallsIdRenamePost operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsIdRenamePostRequest
 */
export interface VideowallsApiVideowallsIdRenamePostRequest {
    /**
     * Идентификатор видеостены
     * @type {number}
     * @memberof VideowallsApiVideowallsIdRenamePost
     */
    readonly id: number;

    /**
     * Объект с новым именем
     * @type {VideowallRenameRequest}
     * @memberof VideowallsApiVideowallsIdRenamePost
     */
    readonly videowallRenameRequest?: VideowallRenameRequest;
}

/**
 * Request parameters for videowallsPost operation in VideowallsApi.
 * @export
 * @interface VideowallsApiVideowallsPostRequest
 */
export interface VideowallsApiVideowallsPostRequest {
    /**
     *
     * @type {Videowall}
     * @memberof VideowallsApiVideowallsPost
     */
    readonly videowall?: Videowall;
}

/**
 * VideowallsApi - object-oriented interface
 * @export
 * @class VideowallsApi
 * @extends {BaseAPI}
 */
export class VideowallsApi extends BaseAPI {
    /**
     *
     * @summary Возвращает список видеостен, доступных пользователю. Без камер.
     * @param {VideowallsApiVideowallsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsGet(requestParameters: VideowallsApiVideowallsGetRequest = {}, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsGet(requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает сгруппированный список видеостен, доступных пользователю. Без камер.
     * @param {VideowallsApiVideowallsGroupedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsGroupedGet(requestParameters: VideowallsApiVideowallsGroupedGetRequest = {}, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsGroupedGet(requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Удаляет видеостену
     * @param {VideowallsApiVideowallsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdDelete(requestParameters: VideowallsApiVideowallsIdDeleteRequest, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdDelete(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает видеостену с камерами.
     * @param {VideowallsApiVideowallsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdGet(requestParameters: VideowallsApiVideowallsIdGetRequest, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdGet(requestParameters.id, requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Делает стену приватной
     * @param {VideowallsApiVideowallsIdMakePrivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdMakePrivatePost(
        requestParameters: VideowallsApiVideowallsIdMakePrivatePostRequest,
        options?: any
    ) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdMakePrivatePost(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Опубликовывает видеостену (перестает быть приватной)
     * @param {VideowallsApiVideowallsIdMakePublicPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdMakePublicPost(
        requestParameters: VideowallsApiVideowallsIdMakePublicPostRequest,
        options?: any
    ) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdMakePublicPost(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Переносит видеостену в другую группу
     * @param {VideowallsApiVideowallsIdMovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdMovePost(requestParameters: VideowallsApiVideowallsIdMovePostRequest, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdMovePost(requestParameters.id, requestParameters.videowallMoveRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Изменяет видеостену и привязанные камеры.  Идентификатор видеостены должен быть указан в uri и теле запроса.  Все неуказанные камеры удалятся с видеостены.  Не изменяет настройки доступа.
     * @param {VideowallsApiVideowallsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdPut(requestParameters: VideowallsApiVideowallsIdPutRequest, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdPut(requestParameters.id, requestParameters.videowall, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Переименовывает видеостену
     * @param {VideowallsApiVideowallsIdRenamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsIdRenamePost(requestParameters: VideowallsApiVideowallsIdRenamePostRequest, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsIdRenamePost(requestParameters.id, requestParameters.videowallRenameRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Создает видеостену и привязывает камеры.  Видеостена создается как приватная.  В запросе камеры могут не указываться (пустая видеостена).
     * @param {VideowallsApiVideowallsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallsApi
     */
    public videowallsPost(requestParameters: VideowallsApiVideowallsPostRequest = {}, options?: any) {
        return VideowallsApiFp(this.configuration)
            .videowallsPost(requestParameters.videowall, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
