import { Authentication } from "@megapolis/react-auth-user";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import React, { lazy } from "react";
import { middleware as authMiddleware } from "shared/api";
import { reducers, states } from "./store";
import { APP_ENV } from "app-env";
import "normalize.css";
import "./index.scss";

const MainPage = lazy(() => import("../pages/main-page"));
const middleWares = [...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }), authMiddleware];

function App() {
    console.log("APP_ENV", APP_ENV);
    return (
        <Authentication
            domain={APP_ENV.REACT_APP_AUTH_URL}
            client_id={APP_ENV.REACT_APP_AUTH_CLIENT_ID}
            scope={APP_ENV.REACT_APP_AUTH_CLIENT_SCOPE}
            debug={process.env.NODE_ENV === "development"}
            states={states}
            reducers={reducers}
            middleWares={middleWares}>
            <MainPage />
        </Authentication>
    );
}

export default App;
