/* tslint:disable */
/* eslint-disable */
/**
 * Videowall Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise } from "axios";
import * as globalImportUrl from "url";
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs } from "../base";
import { Configuration } from "../configuration";
// @ts-ignore
import { VideowallGroupRenameRequest } from "../models";
/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает доступные пользователю группы видеостен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowall/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Переименование группы видеостен
         * @param {VideowallGroupRenameRequest} [videowallGroupRenameRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsRenamePost: async (
            videowallGroupRenameRequest?: VideowallGroupRenameRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/videowall/groups/rename`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json-patch+json";

            localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            const needsSerialization =
                typeof videowallGroupRenameRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(videowallGroupRenameRequest !== undefined ? videowallGroupRenameRequest : {})
                : videowallGroupRenameRequest || "";

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает доступные пользователю группы видеостен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsGet(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await GroupsApiAxiosParamCreator(configuration).groupsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Переименование группы видеостен
         * @param {VideowallGroupRenameRequest} [videowallGroupRenameRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsRenamePost(
            videowallGroupRenameRequest?: VideowallGroupRenameRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GroupsApiAxiosParamCreator(configuration).groupsRenamePost(
                videowallGroupRenameRequest,
                options
            );
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    };
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Возвращает доступные пользователю группы видеостен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGet(options?: any): AxiosPromise<Array<string>> {
            return GroupsApiFp(configuration)
                .groupsGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Переименование группы видеостен
         * @param {VideowallGroupRenameRequest} [videowallGroupRenameRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsRenamePost(videowallGroupRenameRequest?: VideowallGroupRenameRequest, options?: any): AxiosPromise<void> {
            return GroupsApiFp(configuration)
                .groupsRenamePost(videowallGroupRenameRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for groupsRenamePost operation in GroupsApi.
 * @export
 * @interface GroupsApiGroupsRenamePostRequest
 */
export interface GroupsApiGroupsRenamePostRequest {
    /**
     *
     * @type {VideowallGroupRenameRequest}
     * @memberof GroupsApiGroupsRenamePost
     */
    readonly videowallGroupRenameRequest?: VideowallGroupRenameRequest;
}

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     *
     * @summary Возвращает доступные пользователю группы видеостен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsGet(options?: any) {
        return GroupsApiFp(this.configuration)
            .groupsGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Переименование группы видеостен
     * @param {GroupsApiGroupsRenamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsRenamePost(requestParameters: GroupsApiGroupsRenamePostRequest = {}, options?: any) {
        return GroupsApiFp(this.configuration)
            .groupsRenamePost(requestParameters.videowallGroupRenameRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
