import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Templates {
    count: number;
    style: string;
    gridArea: string;
}
export interface InitialModalState {
    showGridModal: boolean;
    showPresetsModal: boolean;
    showSaveModal: boolean;
    showSaveAsPopup: boolean;
    showNotificationModal: boolean;
    notificationText: string;
    confirmModal: boolean;
    Layout: string;
    templates: Templates[];
}

export const initialState: InitialModalState = {
    showGridModal: false,
    showPresetsModal: false,
    showSaveModal: false,
    showSaveAsPopup: false,
    showNotificationModal: false,
    notificationText: "",
    confirmModal: false,
    Layout: "Layout2X2",
    templates: [
        { count: 1, style: "Layout1", gridArea: `"a1 a1" "a1 a1"` },
        { count: 4, style: "Layout2X2", gridArea: `"a1 a2" "a3 a4"` },
        {
            count: 6,
            style: "Layout5Plus1",
            gridArea: `"a1 a1 a2" "a1 a1 a3" "a4 a5 a6"`,
        },
        {
            count: 9,
            style: "Layout3X3",
            gridArea: `"a1 a2 a3" "a4 a5 a6" "a7 a8 a9"`,
        },
        {
            count: 10,
            style: "Layout8Plus2",
            gridArea: `"a1 a2 a3 a4" "a5 a5 a6 a6" "a5 a5 a6 a6" "a7 a8 a9 a10"`,
        },
        {
            count: 13,
            style: "Layout12Plus1",
            gridArea: `"a1 a2 a3 a4" "a5 a6 a6 a7"
    "a8 a6 a6 a9" "a10 a11 a12 a13"`,
        },
        {
            count: 16,
            style: "Layout4X4",
            gridArea: `"a1 a2 a3 a4" "a5 a6 a7 a8" "a9 a10 a11 a12" "a13 a14 a15 a16"`,
        },
        {
            count: 30,
            style: "Layout6X5",
            gridArea: `"a1 a2 a3 a4 a5"
    "a6 a7 a8 a9 a10"
    "a11 a12 a13 a14 a15"
    "a16 a17 a18 a19 a20"
    "a21 a22 a23 a24 a25"
    "a26 a27 a28 a29 a30"`,
        },
    ],
};

const modal = createSlice({
    initialState,
    name: "modal",
    reducers: {
        setCurrentLayout: (state, { payload = "Layout1" }: PayloadAction<string>) => {
            state.Layout = payload ? payload : "Layout1";
        },
        setWallGrid: (state, { payload }: PayloadAction<string>) => {
            state.Layout = payload;
        },
        setShowModal: (state) => {
            state.showGridModal = true;
        },
        setHideModal: (state) => {
            state.showGridModal = false;
        },
        setShowPresetsModal: (state) => {
            state.showPresetsModal = true;
        },
        setHidePresetsModal: (state) => {
            state.showPresetsModal = false;
        },

        setShowSaveModal: (state) => {
            state.showSaveModal = !state.showSaveModal;
        },
        setShowSaveAsPopup: (state) => {
            state.showSaveAsPopup = true;
        },
        setHideSaveAsPopup: (state) => {
            state.showSaveAsPopup = false;
        },
        setShowNotificationModal: (state, { payload: { status } }) => {
            let text = "";
            if (status === 403) {
                text = "Произошла ошибка доступа";
            } else if (status === 500) {
                text = "Произошла ошибка соединения";
            } else if (status === 1) {
                text = "Поле является обязательным";
            } else {
                text = "Произошла ошибка";
            }
            state.showNotificationModal = !state.showNotificationModal;
            state.notificationText = text;
        },
        setShowConfirmModal: (state) => {
            state.confirmModal = true;
        },
        setHideConfirmModal: (state) => {
            state.confirmModal = false;
        },
    },
});

export default {
    state: initialState,
    reducer: modal.reducer,
    actions: modal.actions,
};
