import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Videowall, VideowallGroup } from "shared/api";
import { Camera } from "shared/api/gen/models/camera";
import { VideowallLayout } from "shared/api/gen/models/videowall-layout";
import {
    deleteVideowallById,
    privateVidowall,
    publicVideowall,
    renameGroup,
    renameVideowall
} from "shared/store/videowall/thunks";
import { copyVideowall } from "./thunks";

interface Iindex {
    groupName: string;
    videowallId: number;
    id: number;
}

export interface InitialPresetsState {
    currentPreset: Videowall;
    allGroups: VideowallGroup[];
    allGroupNames: string[];
    accessGroups: string[];
    allLayouts: VideowallLayout[];
    deletePresetModal: boolean;
    deletePresetId: number | null;
    deletePresetLabel: string;
    deleteGroupName: string | null;
    draggableId: number | null;
    draggableGroup: string | null;
    loading: boolean;
    init: boolean;
    videowallCopyLoading: number[];
    videowallDeleteLoading: number[];
    videowallRenameLoading: number[];
    videowallPublishLoading: number[];
    groupRenameLoading: string[];
}

export const initialState: InitialPresetsState = {
    currentPreset: {
        name: "Новая раскладка",
        id: undefined,
        group: "",
        isPublic: false,
        layout: VideowallLayout.Layout2X2,
        cameras: [],
    },
    init: true,
    allGroupNames: [],
    allGroups: [],
    accessGroups: [],
    allLayouts: [],
    deletePresetModal: false,
    deletePresetId: null,
    deletePresetLabel: "",
    deleteGroupName: null,
    draggableId: null,
    draggableGroup: null,
    loading: false,
    videowallCopyLoading: [],
    videowallDeleteLoading: [],
    videowallRenameLoading: [],
    videowallPublishLoading: [],
    groupRenameLoading: [],
};

const presets = createSlice({
    name: "presets",
    initialState,
    reducers: {
        updateLocalGroupsAfterDelete: (state, { payload: { deleteGroupName, deletePresetId } }) => {
            state.allGroups.find((group) => group.group === deleteGroupName)!.videowalls = state.allGroups
                .find((group) => group.group === deleteGroupName)!
                .videowalls!.filter((videowall) => videowall.id !== deletePresetId);
        },
        setDeletePresetId: (state, { payload }) => {
            state.deletePresetId = payload;
        },
        setDeleteGroupName: (state, { payload }) => {
            state.deleteGroupName = payload;
        },
        setDeletePresetLabel: (state, { payload }) => {
            state.deletePresetLabel = payload;
        },
        deleteCamInPreset: (state, { payload }) => {
            //@ts-ignore
            state.currentPreset.cameras[payload] = null;
        },

        updateLocalGroupName: (state, { payload: { newName, groupName } }) => {
            state.allGroups.find((group) => group.group === groupName)!.group = newName;
        },
        showDeletePresetModal: (state) => {
            state.deletePresetModal = !state.deletePresetModal;
        },

        setAccessGroups: (state, { payload }) => {
            state.accessGroups = payload;
        },
        setAllLayouts: (state, { payload }) => {
            state.allLayouts = payload;
        },

        setCurrentPreset: (state, { payload }) => {
            let _cameras: Camera[] = [];
            if (payload.cameras) {
                payload.cameras.map((el: Camera) => (_cameras[el.position] = el));
            }
            state.currentPreset = {
                id: payload.id,
                group: payload.group,
                isPublic: payload.isPublic,
                creatorId: payload.creatorId,
                layout: payload.layout,
                cameras: _cameras,
                name: payload.name,
            };
            state.init = state.init === false ? true : false;
        },
        updateCurrentPresetCams: (
            state,
            { payload: { index, data } }: PayloadAction<{ index: number; data: Camera }>
        ) => {
            //@ts-ignore
            state.currentPreset.cameras[index] = data
                ? {
                      source: data.source,
                      code: data.code,
                      description: data.description,
                      position: index,
                  }
                : null;
        },

        clearCurrentPreset: (state) => {
            return {
                ...state,
                currentPreset: {
                    id: 0,
                    name: "Новая раскладка",
                    group: "",
                    isPublic: false,
                    layout: VideowallLayout.Layout2X2,
                    cameras: [],
                },
            };
        },
        createEmptyVideowall: (state) => {
            state.currentPreset = {
                id: 0,
                name: "Новая раскладка",
                group: "",
                isPublic: false,
                layout: VideowallLayout.Layout2X2,
                cameras: [],
            };
        },
        deletePreset: (state, { payload: { groupName, videowallId } }: PayloadAction<Iindex>) => {
            const copyGroups = [...state.allGroups];
            copyGroups[videowallId] = {
                ...copyGroups[videowallId],
                videowalls: [...state.allGroups[videowallId].videowalls!.filter((item) => item.id !== videowallId)],
            };
            return {
                ...state,
                allGroups: copyGroups,
            };
        },
        saveNewLocalVideowall: (state, { payload: { groupName, data } }) => {
            state.allGroups.find((item: VideowallGroup) => item.group === groupName)!.videowalls = [
                ...state.allGroups.find((item) => item)!.videowalls!,
                data,
            ];
        },
        updateCurrentPresetName: (state, { payload }: PayloadAction<string>) => {
            state.currentPreset.name = payload;
        },
        updateCurrentPresetLayout: (state, { payload }) => {
            state.currentPreset.layout = payload;
        },
        updateAllGroups: (state, { payload }) => {
            state.allGroups = [...payload];
        },
        updateGropNames: (state, { payload }) => {
            state.allGroupNames = [...payload];
        },
        setDraggableData: (state, { payload: { draggableId, draggableGroup } }) => {
            state.draggableId = draggableId;
            state.draggableGroup = draggableGroup;
        },
        moveVideowall: (state, { payload: { videowallId, draggableGroup, droppableGroup } }) => {
            if (draggableGroup === droppableGroup) return;
            //@ts-ignore
            state.allGroups.find((item) => item.group === droppableGroup).videowalls = [
                ...state.allGroups.find((item) => item.group === droppableGroup)!.videowalls!,
                {
                    ...state.allGroups
                        .find((item) => item.group === draggableGroup)
                        ?.videowalls!.find((item) => item.id === videowallId),
                    group: droppableGroup,
                },
            ];
        },
        deleteAfterMove: (state, { payload: { videowallId, draggableGroup, droppableGroup } }) => {
            state.allGroups.find(
                (item: VideowallGroup) => item.group === draggableGroup
            )!.videowalls = state.allGroups
                .find((item: VideowallGroup) => item.group === draggableGroup)!
                .videowalls!.filter((item: Videowall) => item.id !== videowallId);
            state.draggableGroup = null;
            state.draggableId = null;
        },
        deleteEmpyGroups: (state) => {
            state.allGroups = state.allGroups.filter((group: VideowallGroup) => group.videowalls!.length !== 0);
        },
        setLayoutAfterFetch: (state, { payload }) => {
            state.allGroups
                .find((group) => group.group === payload.group)!
                .videowalls!.find((el) => el.id === payload.id)!.layout = payload.layout;
        },
    },
    extraReducers: {
        [copyVideowall.pending.type]: (state, { meta }) => {
            state.loading = true;
            state.videowallCopyLoading.push(meta.arg.videowallId);
        },
        [copyVideowall.fulfilled.type]: (state, { meta, payload }) => {
            const { presetIndex, videowallId } = meta.arg;
            state.videowallCopyLoading = state.videowallCopyLoading.filter((id) => id !== videowallId);
            state.allGroups
                .find((group) => group.group === payload.group)
                ?.videowalls!.splice(presetIndex + 1, 0, payload);
        },
        [copyVideowall.rejected.type]: (state, { meta }) => {
            state.videowallCopyLoading = state.videowallCopyLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [deleteVideowallById.pending.type]: (state, { meta }) => {
            state.videowallDeleteLoading.push(meta.arg.deletePresetId);
        },
        [deleteVideowallById.fulfilled.type]: (state, { meta }) => {
            const { groupName, deletePresetId } = meta.arg;
            /**Удаляем видеостену локально, после удаления на сервере */
            state.allGroups.find((group) => group.group === groupName)!.videowalls = state.allGroups
                .find((group) => group.group === groupName)!
                .videowalls!.filter((videowall) => videowall.id !== deletePresetId);
            /**Удаляем группу, если в ней не осталось пресетов для видеостен */
            state.allGroups = state.allGroups.filter((group: VideowallGroup) => group.videowalls!.length !== 0);
            state.videowallDeleteLoading = state.videowallDeleteLoading.filter((id) => id !== deletePresetId);
        },
        [deleteVideowallById.rejected.type]: (state, { meta }) => {
            state.videowallDeleteLoading = state.videowallDeleteLoading.filter((id) => id !== meta.arg.deletePresetId);
        },
        [renameVideowall.pending.type]: (state, { meta }) => {
            state.videowallRenameLoading.push(meta.arg.videowallId);
        },
        [renameVideowall.fulfilled.type]: (state, { meta }) => {
            const { groupName, id, newName } = meta.arg;
            const currentName = state.currentPreset.name;
            /**Обновляем локально название видеостены в списке раскладок*/
            state.allGroups
                .find((group) => group.group === groupName)!
                .videowalls!.find((videowall) => videowall.id === id)!.name = newName;
            if (currentName !== newName) {
                state.currentPreset.name = newName;
            }

            state.videowallRenameLoading = state.videowallRenameLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [renameVideowall.rejected.type]: (state, { meta }) => {
            state.videowallRenameLoading = state.videowallRenameLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [publicVideowall.pending.type]: (state, { meta }) => {
            state.videowallPublishLoading.push(meta.arg.videowallId);
        },
        [publicVideowall.fulfilled.type]: (state, { meta }) => {
            const { groupName, videowallId } = meta.arg;
            const videowall = state.allGroups
                .find((group) => group.group === groupName)!
                .videowalls!.find((videowall) => videowall.id === videowallId)!;
            videowall.isPublic = !videowall.isPublic;
            state.videowallPublishLoading = state.videowallPublishLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [publicVideowall.rejected.type]: (state, { meta }) => {
            state.videowallPublishLoading = state.videowallPublishLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [privateVidowall.pending.type]: (state, { meta }) => {
            state.videowallPublishLoading.push(meta.arg.videowallId);
        },
        [privateVidowall.fulfilled.type]: (state, { meta }) => {
            const { groupName, videowallId } = meta.arg;
            const videowall = state.allGroups
                .find((group) => group.group === groupName)!
                .videowalls!.find((videowall) => videowall.id === videowallId)!;
            videowall.isPublic = !videowall.isPublic;
            state.videowallPublishLoading = state.videowallPublishLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [privateVidowall.rejected.type]: (state, { meta }) => {
            state.videowallPublishLoading = state.videowallPublishLoading.filter((id) => id !== meta.arg.videowallId);
        },
        [renameGroup.pending.type]: (state, { meta }) => {
            state.groupRenameLoading.push(meta.arg.oldName);
        },
        [renameGroup.fulfilled.type]: (state, { meta }) => {
            state.groupRenameLoading = state.groupRenameLoading.filter((name) => name !== meta.arg.oldName);
        },
        [renameGroup.rejected.type]: (state, { meta }) => {
            state.groupRenameLoading = state.groupRenameLoading.filter((name) => name !== meta.arg.oldName);
        },
    },
});

export default {
    state: initialState,
    reducer: presets.reducer,
    actions: presets.actions,
};
