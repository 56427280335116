import axios from "axios";

export const middleware: import("redux").Middleware = () => (next) => (action) => {
    if (action.type === "redux-oidc/USER_FOUND") {
        const { access_token } = action.payload;
        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    }
    return next(action);
};

export * from "./gen";
export * from "./gen/models";
