/* tslint:disable */
/* eslint-disable */
/**
 * Videowall Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum VideowallLayout {
    Layout1 = 'Layout1',
    Layout2X2 = 'Layout2X2',
    Layout5Plus1 = 'Layout5Plus1',
    Layout3X3 = 'Layout3X3',
    Layout8Plus2 = 'Layout8Plus2',
    Layout12Plus1 = 'Layout12Plus1',
    Layout4X4 = 'Layout4X4',
    Layout6X5 = 'Layout6X5'
}



